import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 998.000000 779.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,779.000000) scale(0.100000,-0.100000)">


<path d="M4367 5159 c-233 -470 -434 -875 -446 -901 -13 -27 -26 -48 -30 -48
-5 0 -236 114 -515 253 -278 139 -508 251 -511 249 -3 -3 -5 -583 -5 -1289 l0
-1283 1268 0 c697 0 1567 -3 1935 -7 534 -5 667 -4 667 6 0 7 -70 153 -155
323 -85 171 -155 314 -155 319 0 5 17 7 38 3 47 -7 55 1 76 86 43 180 66 343
66 470 -1 197 -56 472 -127 630 -14 30 -32 71 -40 90 -44 104 -99 194 -114
184 -5 -3 -9 1 -9 9 0 8 -7 17 -14 20 -8 3 -22 20 -32 38 -9 18 -25 42 -36 53
-33 35 -98 124 -98 136 0 5 -6 10 -13 10 -7 0 -20 12 -29 28 -9 15 -33 45 -52
67 -20 22 -36 46 -36 53 0 6 -6 12 -13 12 -7 0 -44 29 -82 65 -37 36 -72 65
-77 65 -4 0 -20 13 -35 29 -16 15 -42 36 -60 45 -18 9 -37 24 -42 33 -6 10
-11 15 -11 11 0 -4 -26 8 -58 26 -92 52 -232 114 -287 127 -27 6 -57 15 -65
18 -8 4 -116 211 -240 459 -124 248 -229 454 -233 458 -4 4 -197 -377 -430
-847z m270 -137 c18 -37 77 -152 131 -257 154 -297 307 -595 462 -900 78 -154
158 -311 177 -348 20 -37 33 -70 30 -73 -5 -5 -1357 664 -1357 672 0 2 17 37
38 76 22 40 78 150 127 243 48 94 116 224 150 290 34 66 91 175 126 243 34 67
68 122 73 122 6 0 26 -31 43 -68z m1007 -579 c14 -12 26 -19 26 -17 0 3 12 -7
28 -21 15 -14 34 -28 42 -31 16 -5 79 -83 116 -141 12 -19 30 -44 41 -56 31
-34 103 -181 103 -209 0 -8 4 -18 9 -23 11 -13 49 -140 68 -229 12 -59 35
-276 29 -283 -17 -20 -536 1013 -536 1068 0 4 11 -2 24 -14 13 -12 35 -32 50
-44z m-2101 -220 c303 -152 287 -144 287 -149 0 -2 -9 -21 -20 -41 -11 -21
-20 -45 -20 -53 1 -8 12 6 25 32 18 34 32 48 54 53 26 6 53 45 30 45 -5 0 -9
5 -9 11 0 8 5 8 15 -1 17 -14 20 -50 5 -50 -5 0 -10 -9 -10 -20 0 -11 5 -20
10 -20 6 0 10 -13 10 -30 0 -28 2 -29 26 -20 14 6 23 6 20 1 -4 -5 0 -11 6
-14 10 -3 10 -7 2 -18 -6 -8 -16 -30 -22 -49 -11 -32 -11 -33 4 -15 8 11 17
30 21 43 3 12 9 22 14 22 5 0 9 7 9 15 0 8 4 15 9 15 5 0 171 -82 368 -182
197 -100 377 -191 401 -202 23 -11 42 -25 42 -30 0 -6 -114 -69 -253 -139
-138 -71 -331 -169 -427 -218 -398 -204 -687 -349 -696 -349 -5 0 -37 -14 -71
-32 -34 -18 -65 -29 -70 -25 -4 5 -9 352 -11 773 -3 584 -2 764 7 764 7 0 117
-53 244 -117z m2077 -1125 c84 -165 150 -301 148 -304 -8 -7 -1573 -5 -1575 3
-1 4 280 148 625 320 546 271 629 309 639 296 7 -9 80 -150 163 -315z"/>
<path d="M4582 4973 c-17 -31 -15 -39 8 -39 21 0 23 9 11 41 -8 19 -8 19 -19
-2z"/>
<path d="M4165 4150 c-4 -7 -1 -16 6 -21 23 -14 32 -10 18 9 -7 9 -14 18 -15
20 -1 2 -5 -2 -9 -8z"/>
<path d="M5273 3623 c5 -7 -1 -13 -15 -15 -21 -3 -19 -6 17 -31 22 -15 43 -27
48 -27 10 0 -23 57 -42 73 -11 9 -13 9 -8 0z"/>
<path d="M5897 3926 c26 -52 49 -93 51 -91 6 5 -79 174 -90 181 -5 3 13 -37
39 -90z"/>
<path d="M3330 4252 c0 -26 10 -33 32 -21 16 9 17 12 4 24 -20 20 -36 19 -36
-3z"/>
<path d="M3722 4050 c7 -11 16 -16 21 -11 12 12 0 31 -19 31 -12 0 -12 -3 -2
-20z"/>
<path d="M3831 3984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4034 3896 c-8 -21 -4 -25 19 -17 17 6 17 8 3 20 -13 11 -17 11 -22
-3z"/>
<path d="M4660 3565 c0 -28 5 -30 40 -10 26 14 27 13 -25 30 -11 3 -15 -3 -15
-20z"/>
<path d="M3335 3308 c-4 -31 -5 -63 -2 -70 4 -13 37 52 51 102 7 23 6 24 -8 7
-14 -18 -15 -18 -24 -1 -8 14 -12 6 -17 -38z"/>
<path d="M3582 3020 c-26 -47 -17 -50 38 -14 40 27 52 41 26 31 -8 -3 -17 1
-20 9 -9 23 -20 16 -44 -26z"/>
<path d="M5396 3345 c-19 -14 -19 -15 5 -15 14 0 31 -6 38 -12 10 -11 10 -8 0
15 -14 31 -17 32 -43 12z"/>
<path d="M4415 2860 l-60 -28 73 -1 c65 -1 88 8 62 24 -5 3 -7 12 -3 20 7 20
-2 18 -72 -15z"/>
<path d="M5650 2869 c0 -7 -8 -18 -17 -25 -16 -12 -14 -13 20 -14 40 0 42 2
27 31 -12 21 -30 26 -30 8z"/>
<path d="M5494 5883 c-7 -29 -19 -76 -72 -273 -17 -63 -29 -116 -27 -119 3 -2
46 -18 97 -36 174 -60 187 -65 251 -100 36 -19 70 -35 76 -35 6 0 45 -20 87
-45 41 -25 78 -45 80 -45 10 0 110 -73 117 -86 4 -8 12 -14 18 -14 18 0 227
-163 289 -225 15 -16 31 -23 42 -19 13 4 16 1 12 -10 -8 -20 6 -43 16 -27 5 9
10 8 20 -4 10 -12 10 -15 -1 -15 -7 0 -2 -11 13 -27 14 -16 30 -37 36 -48 6
-11 12 -14 12 -7 0 17 20 15 20 -2 0 -17 28 -41 37 -32 3 3 1 17 -5 31 -10 21
-7 34 17 83 16 31 29 69 29 84 -1 27 22 58 41 58 6 0 11 -4 11 -10 0 -5 -7
-10 -15 -10 -9 0 -15 -9 -15 -22 1 -22 1 -22 25 5 l25 28 12 -25 c16 -37 24
-32 23 17 -1 44 -19 87 -35 87 -14 0 -12 27 3 33 7 4 6 6 -5 6 -10 1 -21 17
-30 44 -7 23 -17 42 -23 41 -5 -1 -16 13 -23 30 -14 34 -31 48 -49 41 -7 -2
-20 11 -29 30 -20 43 -85 110 -96 99 -3 -2 -18 14 -33 36 -15 22 -31 40 -36
40 -4 0 -24 15 -44 34 -35 33 -78 63 -255 177 -89 58 -267 147 -347 174 -27 9
-61 22 -78 30 -41 18 -156 55 -172 55 -7 0 -16 -12 -19 -27z m1186 -804 c0 -5
-4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z"/>
<path d="M7160 4458 c0 -16 5 -28 10 -28 13 0 13 20 0 40 -8 12 -10 9 -10 -12z"/>
<path d="M6750 4413 c0 -12 7 -34 14 -50 22 -42 29 -66 27 -86 -1 -10 5 -29
13 -42 9 -13 16 -31 16 -39 0 -9 6 -30 14 -48 14 -33 24 -70 52 -188 17 -71
17 -72 34 -180 54 -338 29 -705 -70 -1039 -26 -88 -25 -90 70 -117 30 -9 109
-37 175 -61 134 -50 192 -66 206 -58 30 19 102 291 123 470 9 72 18 146 21
165 13 101 1 502 -16 548 -5 13 -9 37 -9 53 0 45 -46 230 -91 369 -45 137 -72
201 -100 242 -11 14 -19 33 -19 42 0 10 -9 16 -22 16 -17 0 -19 -3 -9 -9 10
-7 5 -11 -18 -16 -31 -7 -41 -20 -20 -27 9 -3 9 -10 0 -27 -9 -17 -18 -21 -34
-17 -12 3 -33 6 -47 7 -14 0 -19 3 -12 6 6 2 12 9 12 14 0 6 -4 7 -10 4 -5 -3
-10 3 -10 13 0 15 -5 19 -20 15 -22 -6 -80 14 -80 28 0 5 5 9 10 9 6 0 10 5
10 10 0 6 -37 10 -91 10 -81 0 -90 -2 -79 -15 7 -9 10 -18 7 -22 -4 -3 -15 5
-26 18 -20 24 -20 24 -21 2z"/>
<path d="M3840 4295 c7 -8 16 -15 21 -15 5 0 9 7 9 15 0 9 -9 15 -21 15 -18 0
-19 -2 -9 -15z"/>
<path d="M6506 2715 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 -9 0
-12 -6 -9 -15z"/>
<path d="M6590 2510 c0 -11 39 -34 46 -27 6 6 -25 37 -37 37 -5 0 -9 -4 -9
-10z"/>
<path d="M4277 1919 c-92 -22 -156 -73 -195 -158 -21 -46 -24 -64 -20 -126 9
-138 72 -202 263 -265 120 -39 142 -50 175 -85 19 -20 25 -37 25 -71 0 -90
-85 -126 -196 -85 -69 26 -144 95 -217 199 -15 21 -32 32 -47 32 -22 0 -24 -4
-21 -42 11 -143 20 -226 28 -247 8 -22 9 -22 33 -7 21 14 34 14 136 1 139 -19
181 -19 240 -1 133 40 188 110 205 255 10 93 -10 156 -71 216 -53 53 -75 63
-243 116 -108 34 -147 60 -158 105 -15 60 50 114 137 114 35 0 56 -7 86 -29
42 -31 98 -95 128 -148 20 -33 40 -47 56 -37 5 3 9 58 9 123 0 132 -7 151 -53
130 -19 -9 -40 -8 -95 5 -77 17 -145 19 -205 5z"/>
<path d="M5889 1917 c-7 -7 -78 -190 -158 -407 l-146 -394 -33 -8 c-24 -6 -32
-14 -30 -26 3 -14 18 -17 106 -20 85 -2 103 0 108 13 9 23 -14 45 -47 45 -17
0 -29 5 -29 13 0 6 14 53 32 103 l32 92 59 6 c108 11 155 8 165 -11 10 -19 64
-181 66 -198 0 -5 -15 -14 -34 -21 -19 -6 -35 -18 -35 -25 0 -11 38 -14 205
-14 l205 0 0 25 c0 20 -6 26 -26 28 -24 3 -31 14 -71 125 -25 67 -53 147 -63
177 -9 30 -22 63 -27 73 -10 21 -137 361 -145 392 -3 11 -10 26 -16 33 -12 16
-103 16 -118 -1z m-39 -311 c0 -13 14 -53 30 -89 17 -36 32 -78 33 -94 l2 -28
-82 0 c-46 0 -83 1 -83 2 0 1 18 54 41 117 40 112 59 142 59 92z"/>
<path d="M7257 1863 c-14 -37 -43 -117 -65 -178 -61 -165 -167 -452 -192 -517
-20 -55 -23 -58 -55 -58 -39 0 -52 -14 -34 -36 9 -12 33 -14 113 -12 89 3 101
5 104 22 5 23 -14 36 -51 36 -23 0 -27 3 -22 19 3 11 18 57 34 103 l28 83 44
7 c43 6 179 11 179 5 0 -1 16 -47 35 -102 41 -118 42 -125 13 -125 -32 0 -52
-15 -45 -34 6 -14 32 -16 206 -16 186 0 199 1 204 19 6 26 -3 38 -33 42 -23 4
-31 21 -93 194 -38 105 -72 199 -77 210 -10 23 -91 245 -95 260 -1 6 -9 26
-17 45 -8 19 -19 49 -24 65 -9 29 -12 30 -71 33 l-61 3 -25 -68z m12 -328 c24
-47 41 -99 41 -121 0 -27 -17 -30 -89 -13 -31 7 -47 6 -57 -2 -21 -18 -18 0
20 109 52 148 48 141 62 91 7 -24 18 -53 23 -64z"/>
<path d="M2475 1910 c-10 -15 2 -27 33 -33 22 -4 33 -17 52 -59 13 -29 57
-122 97 -207 l73 -154 -2 -171 -3 -171 -30 -5 c-22 -4 -31 -11 -33 -28 l-3
-22 198 2 198 3 0 25 c0 21 -5 26 -32 28 l-33 3 0 178 c0 171 1 180 29 257 17
43 48 120 71 169 23 50 47 102 53 117 9 22 18 27 54 30 24 2 63 2 88 0 l45 -4
0 -374 c0 -291 -3 -376 -12 -379 -7 -3 -24 -6 -38 -8 -17 -3 -25 -10 -25 -23
0 -18 11 -19 260 -19 285 0 343 9 414 62 60 46 92 151 71 231 -18 65 -51 106
-111 136 -49 24 -51 26 -28 35 63 24 114 123 103 200 -14 100 -66 153 -169
176 -58 13 -136 15 -440 13 -343 -3 -370 -4 -373 -20 -3 -14 4 -18 30 -19 18
-1 43 -4 55 -7 23 -7 22 -8 -25 -112 -27 -58 -60 -123 -75 -145 l-26 -39 -31
68 c-16 37 -30 70 -30 72 0 2 -11 27 -24 56 -40 90 -41 96 -9 100 20 2 29 9
31 26 l3 22 -200 0 c-122 0 -202 -4 -206 -10z m1214 -101 c14 -47 14 -148 0
-203 -11 -44 -37 -66 -79 -66 -19 0 -20 8 -20 163 l0 163 44 -10 c39 -9 45
-14 55 -47z m-9 -354 c40 -21 53 -68 48 -181 -5 -120 -22 -147 -92 -152 l-46
-3 0 175 0 176 30 0 c17 0 44 -7 60 -15z"/>
<path d="M4734 1905 c-9 -23 5 -35 42 -35 l34 0 0 -375 0 -375 -25 -10 c-14
-5 -31 -9 -38 -7 -7 1 -12 -7 -12 -18 0 -19 8 -20 210 -23 126 -2 240 1 285 8
239 37 350 172 350 423 0 242 -87 367 -287 411 -101 22 -551 24 -559 1z m481
-64 c66 -42 85 -117 85 -341 0 -294 -40 -380 -180 -380 l-50 0 0 373 0 373 63
-6 c34 -4 71 -13 82 -19z"/>
<path d="M6287 1913 c-3 -5 -8 -77 -12 -161 -7 -153 -7 -154 14 -150 16 2 33
27 66 97 47 100 83 145 126 155 24 5 27 2 33 -25 3 -17 6 -181 6 -363 0 -245
-3 -335 -12 -344 -7 -7 -31 -12 -53 -12 -49 0 -58 -6 -44 -32 9 -17 23 -18
237 -16 226 3 227 3 230 25 3 23 -8 28 -68 35 l-25 3 -3 367 -2 367 27 -5 c62
-11 93 -52 139 -184 16 -48 28 -66 42 -68 18 -3 21 4 27 72 4 41 3 112 -1 158
l-9 83 -357 3 c-196 1 -359 -1 -361 -5z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
